<template>
    <div>
        <nav class="mt-0 h-100 sidebar-menu mobile-menu" v-if="expandMenu" style="z-index: 999 !important;">
            <div class="row no-gutters flex-column flex-nowrap w-100 h-100 pt-2 pl-3" style="position: relative;margin-bottom: 30px !important;">
                <div>
                    <div class="avatar-wrapper position-relative mx-auto" style="width: 67px;">
                        <img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="70" height="70" />
                        <div class="profilePicEdit-parent pointer" v-if="$route.params.id == loggedInUser.user_id">
                            <label class="profilePicEdit m-0 pointer" for="fileprofile" v-if="loggedInUser.user_id==$route.params.id">
                                <img style="border-radius: 50%;" width="20" src="/static/images/editgreynew.svg">
                            </label>
                            <input type="file" style="display: none;" ref="myProfilePic"
                                    accept="image/x-png, image/gif, image/jpeg" id="fileprofile"
                                    @change="onUserProfile" />
                        </div>
                    </div>
                    <div class="delete-image pointer" v-if="loggedInUser.profile_pic && $route.params.id == loggedInUser.user_id">
                        <label class="mb-0" @click="deleteProfile">
                            <i class="icon icon-delete pointer text-danger fs-12" style="background-color: #ecf1f9;border-radius: 50%;padding: 5px;"></i>
                        </label>
                    </div>
                    <div class="fw-600 pb-2 pl-2 text-center">
                        <span class="username mx-3 d-md-block"
                        style="font-size: 14px;color: #00448b;"
                        :title="loggedInUser.full_name">
                            {{loggedInUser.full_name}}
                        </span>
                    </div>
                    <div class="fw-600 pb-2 pl-2 text-center">
                        <span class="username mx-3 d-md-block"
                        style="font-size: 14px;color: #00448b;"
                        :title="loggedInUser.primary_email">
                            {{loggedInUser.primary_email}}
                        </span>
                    </div>
                </div>
                <div style="border: solid 0.5px #00448b" class="ml-2 mr-2"></div>
                <div style="overflow: auto;">
                    <ul class="settings-left-sec-list mt-3">
                        <li>
                            <div class="user-tab py-2 pl-3 pointer d-flex align-items-center" :class="{'tab-active':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders')) && expand}">
                                <div v-if="($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders')) && expand" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                                </div>
                                <i class="crmnew-user iconsize-16" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}" style="font-size: 20px !important; color: #afafaf;width: 30px;height: 20px;"></i>
                                <span class="fs-14 fw-500" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}">Admin Profile</span>
                                <i v-if="sub_menu" @click="subMenu()" class="icon-chevron-up pl-8 arrow"  style="font-size: 32px;" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}"></i>
                                <i v-else @click="subMenu()" class="icon-chevron-down pl-8 arrow" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}" style="font-size: 32px;"></i>
                            </div>
                        </li>
                        <div>
                        <li class="admin-profile-submenu pt-3" v-if="sub_menu">
                            <div class="user-tab py-3 mt-12 mb-2 pointer d-flex align-items-center" @click="gotoPage('basicinfo')">
                                <i class="crmnew-user iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo'))}">Basic Information</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-3 mt-12 mb-2 pointer d-flex align-items-center" @click="gotoPage('communication')">
                                <i class="crmnew-phone iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication'))}">Communication</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-3 mt-12 mb-2 pointer d-flex align-items-center" @click="gotoPage('address')">
                                <i class="crmnew-marker iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/AddressView') || $route.path.startsWith('/address'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span  class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/AddressView') || $route.path.startsWith('/address'))}">Address</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-3 mt-12 mb-2 pointer d-flex align-items-center" @click="gotoPage('preferences')">
                                <i class="crmnew-preference iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences'))}">Preferences</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-3 mt-12 mb-2 pointer d-flex align-items-center" @click="gotoPage('health')">
                                <span>
                                    <img v-if="($route.path.startsWith('/HealthView') || $route.path.startsWith('/health'))" height="15px important" width="15px !important" src="/static/images/health-icon-new-blue.svg" />
                                    <img v-else height="15px !important" width="15px !important" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/health-icon.svg" >
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/HealthView') || $route.path.startsWith('/health'))}">Health</span>
                            </div>
                        </li> 
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('reminders')">
                                <span>
                                    <img v-if="($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))" height="14px important" width="14px !important" src="/static/images/reminders-icon-new-blue.svg" />
                                    <img v-else height="14px !important" width="14px !important" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/reminders-icon.svg" ></span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}">Reminders</span>
                            </div>
                        </li>
                        <div v-if="sub_menu" style="border: solid 0.5px #d9d8d8" class="ml-2 mr-2"></div></div>
                    </ul>
                    <div class="user-tab py-3 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company') || $route.path.startsWith('/organization' ) || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/linkemp') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}" @click="gotoPage('company')">
                        <div v-if="($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/linkemp'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                        </div>
                        <div class="text-center" style="width: 28px;">
                            <span>
                                <img v-if="($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp' ))" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/company-blue.png" alt="company" />
                                <img v-else height="20px" src="/static/images/company-gray.svg" alt="Company" />
                            </span>
                        </div>
                        <span title="Manage Organization" v-if="loggedInUser.user_has == 'organization'" class="fs-14 fw-500 pl-1" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/linkemp'))}">
                            Manage Organization
                        </span>
                        <span  title="Manage Company" v-else-if="(loggedInUser.user_has == 'standalone' || loggedInUser.user_has == 'company')" class="fs-14 fw-600 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors'))}">
                            Manage Company
                        </span>
                        <span title="Manage Companies" v-else-if="loggedInUser.user_has == 'companies'" class="fs-14 fw-500 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors'))}">
                            Manage Companies
                        </span>
                        <span title="Create Company" v-else-if="expand" class="fs-14 fw-600 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization'))}">
                            Create Company
                        </span>
                    </div>
                    <ul class="settings-left-sec-list mt-2" v-if="show_company_menu && ($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))">
                        <li>
                            <div class="user-tab py-2 pl-3 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}">
                                <div v-if="show_company_menu && ($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                                </div>
                                <span class="fs-14 fw-500 pl-8 text-truncate" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}" style="width: 125px !important;" :title="comp_data.company_name">{{comp_data.company_name}}</span>
                                <i v-if="sub_menu" @click="subMenuOrganization()" class="icon-chevron-up pl-8 arrow"  style="font-size: 32px;" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}"></i>
                                <i v-else @click="subMenuOrganization()" class="icon-chevron-down pl-8 arrow" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}" style="font-size: 32px;"></i>
                            </div>
                        </li>
                        <div>
                        <li class="admin-profile-submenu pt-2" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 mb-1 pointer d-flex align-items-center" @click="gotoPage('info')">
                                <img v-if="$route.path.startsWith('/company/view/')" width="18px" height="15px" src="/static/images/Blue-Info.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Info.svg"/>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/view/'))}">Basic Information</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 mb-1 pointer d-flex align-items-center" @click="gotoPage('employee')">
                                <img v-if="$route.path.startsWith('/employee')" width="18px" height="15px" src="/static/images/Blue-Employee.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Employee.svg"/>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/employee'))}">Employee</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 mb-1 pointer d-flex align-items-center" @click="gotoPage('businessunit')">
                                <img v-if="($route.path.startsWith('/businessunit/listview/'))" width="18px" height="15px" src="/static/images/Blue-Business.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Business.svg"/>
                                <span  class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/businessunit'))}">Business Unit</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 mb-1 pointer d-flex align-items-center" @click="gotoPage('department')">
                                <img v-if="($route.path.startsWith('/departmentunit'))" width="18px" height="15px" src="/static/images/Blue-department.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Department.svg"/>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/departmentunit'))}">Departments</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('comp_products')">
                                <span>
                                    <img v-if="($route.path.startsWith('/product/view/') || $route.path.startsWith('/product/details'))" width="18px" height="15px" src="/static/images/Blue-Product.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Product.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/product/view/') || $route.path.startsWith('/product/details'))}">Products</span>
                            </div>
                        </li> 
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('bank_details')">
                                <span>
                                    <img v-if="($route.path.startsWith('/company/bank_info/'))" width="18px" height="15px" src="/static/images/Blue-Bank.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Bank.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/bank_info/'))}">Bank Details</span>
                            </div>
                        </li>

                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('outlet_details')">
                                <span>
                                    <img v-if="($route.path.startsWith('/company/outlet/'))" width="18px" height="15px" src="/static/images/Blue-Outlet.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Outlet.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/outlet/'))}">Outlets</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('consultant')">
                                <span>
                                    <img v-if="($route.path.startsWith('/consultants'))" width="18px" height="15px" src="/static/images/Blue-Consultant.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Consultant.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants'))}">Consultants</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('investors')">
                                <span>
                                    <img v-if="($route.path.startsWith('/investors'))" width="18px" height="15px" src="/static/images/blue-Investors.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/black-investors.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/investors'))}">Investors</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('bankers')">
                                <span>
                                    <img v-if="($route.path.startsWith('/bankers'))" width="18px" height="15px" src="/static/images/blue-banker.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/banker.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/bankers'))}">Bankers</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('suppliers')">
                                <span>
                                    <img v-if="($route.path.startsWith('/suppliers'))" width="18px" height="15px" src="/static/images/blue-suppl.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/supplier.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/suppliers'))}">Suppliers</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('board')">
                                <span>
                                    <img v-if="($route.path.startsWith('/board'))" width="18px" height="15px" src="/static/images/boardmeeting-blue.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/boardmeeting-black.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/board'))}">Board Members</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('shareholder')">
                                <span>
                                    <img v-if="($route.path.startsWith('/shareholder'))" width="18px" height="15px" src="/static/images/shareholder-blue.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/shareholder-black.svg"/>
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/shareholder'))}">Shareholders</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu_organization">
                            <div class="user-tab py-3 mt-12 pointer d-flex align-items-center" @click="gotoPage('company_setting')">
                                <span>
                                    <img v-if="$route.path.startsWith('/company/settings/')" width="18px" height="15px" src="/static/images/Settings-Company-Blue.svg" alt="settings" />
                                    <img v-else width="18px" height="15px" src="/static/images/Settings-Company-Gray.svg" alt="settings" />
                                </span>
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/settings/'))}">Company Settings</span>
                            </div>
                        </li>
                        <div v-if="sub_menu_organization" style="border: solid 0.5px #d9d8d8" class="ml-2 mr-2"></div></div>
                    </ul>
                    <div v-if="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'Admin' || loggedInUser.user_role == 'employee'" title="Product Access" class="user-tab py-3 mb-2 pointer d-flex align-items-center" :class="{'tab-active':$route.path == '/product/view'}" @click="gotoPage('products')">
                        <div v-if="$route.path == '/product/view'" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                        </div>
                        <span class="pl-1">
                            <img v-if="$route.path == '/product/view'" width="28px" height="20px" src="/static/images/products-setting-blue.svg" alt="product_access" />
                            <img v-else width="28px" height="20px" src="/static/images/products-setting.svg" alt="product_access" />
                        </span>
                        <span class="fs-14 fw-500 pl-2" :class="{'defaultDarkBlue':$route.path == '/product/view'}">Product Access</span>
                    </div>
                    <div class="user-tab py-3 mb-2 pointer d-flex align-items-center" :class="{'tab-active':$route.path.startsWith('/settings')}" @click="gotoPage('settings')" title="Settings">
                        <div v-if="$route.path.startsWith('/settings')" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                        </div>
                        <span class="pl-1">
                            <img v-if="$route.path.startsWith('/settings')" width="20px" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" alt="settings" />
                            <img v-else width="20px" height="20px" src="/static/images/settings-gray.svg" alt="settings" />
                        </span>
                        <span class="fs-14 fw-500 pl-2" :class="{'defaultDarkBlue':$route.path.startsWith('/settings')}">Settings</span>
                    </div>
                </div>
            </div>
        </nav>
        <div class="left-navbar" :class="{'left-navbar-open':expand}">
            <div class="shortcuts row no-gutters align-items-center">
                <a class="shortcut-button mx-1 app-title text-white menu-btn-hide" style="cursor: auto;"
                    href="javascript:;">
                    <img src="/static/images/digicollect_new_logo_ui.svg" style="width: 26px; height: 22px;">
                </a>
            </div>
            <div class="open-full-menu" :class="{'open-full-menu-expand':expand}">
                <img @click="expandLeftBar" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/arrow_right_blue.png" width="30">
            </div>
            <nav class="mt-0 h-100 sidebar-menu" style="margin-top: -20px !important;">
                <!-- <nav class="mt-0 h-100 overflow-auto-y"> -->
                <div class="row no-gutters flex-column flex-nowrap w-100 h-100" style="position: relative;">
                    <div v-if="!expand">
                        <div class="avatar-wrapper pb-2 position-relative mx-auto" style="width: 45px;">
                            <el-popover
                                placement="bottom-start"
                                width="250"
                                popper-class="user-profile-popover"
                                trigger="hover">
                                <div>
                                    {{loggedInUser.full_name}}
                                    <br/>
                                    {{loggedInUser.primary_email}}
                                </div>
                                <img slot="reference" style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="45" height="45" />
                            </el-popover>
                            <div class="profilePicEdit-parent pointer" v-if="$route.params.id == loggedInUser.user_id">
                                <label class="profilePicEdit m-0 pointer" for="fileprofile" v-if="loggedInUser.user_id==$route.params.id">
                                    <img style="border-radius: 50%;" width="20" src="/static/images/editgreynew.svg">
                                </label>
                                <input type="file" style="display: none;" ref="myProfilePic"
                                        accept="image/x-png, image/gif, image/jpeg" id="fileprofile"
                                        @change="onUserProfile" />
                                <!-- <img style="border-radius: 50%;" src="/static/images/editgreynew.svg"> -->
                            </div>
                            <div class="delete-image pointer" v-if="loggedInUser.profile_pic && $route.params.id == loggedInUser.user_id">
                                <label class="mb-0" @click="deleteProfile">
                                    <i class="icon icon-delete pointer text-danger fs-12" style="background-color: #ecf1f9;border-radius: 50%;padding: 5px;"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div v-if="expand">
                        <div class="avatar-wrapper mx-auto position-relative mx-auto" style="width: 70px;">
                            <img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="70" height="70" />
                            <div class="profilePicEdit-parent pointer" v-if="$route.params.id == loggedInUser.user_id">
                                <label class="profilePicEdit m-0 pointer" for="fileprofile" v-if="loggedInUser.user_id==$route.params.id">
                                    <img style="border-radius: 50%;" width="20" src="/static/images/editgreynew.svg">
                                </label>
                                <input type="file" style="display: none;" ref="myProfilePic"
                                        accept="image/x-png, image/gif, image/jpeg" id="fileprofile"
                                        @change="onUserProfile" />
                                <!-- <img style="border-radius: 50%;" src="/static/images/editgreynew.svg"> -->
                            </div>
                            <div class="delete-image pointer" v-if="loggedInUser.profile_pic && $route.params.id == loggedInUser.user_id">
                                <label class="mb-0" @click="deleteProfile">
                                    <i class="icon icon-delete pointer text-danger fs-12" style="background-color: #ecf1f9;border-radius: 50%;padding: 5px;"></i>
                                </label>
                            </div>
                        </div>
                        <div class="fw-700 pt-2 pb-2 pl-2 text-center">
                            <span class="username mx-3 d-md-block"
                            style="font-size: 14px;padding-top:2px;color: #00448b;"
                            :title="loggedInUser.full_name">
                                {{loggedInUser.full_name}}
                            </span>
                        </div>
                        <div class="fw-500 pb-2 pl-2 text-center">
                            <span class="username mx-3 d-md-block"
                            style="font-size: 14px;color: #00448b;"
                            :title="loggedInUser.primary_email">
                                {{loggedInUser.primary_email}}
                            </span>
                        </div>
                    </div>
                    <div style="border: solid 0.5px #00448b" class="ml-2 mr-2"></div>
                    <div class="dropdown mt-2" v-if="!expand && ($route.path.startsWith('/product/details'))">
                        <div class="user-tab py-3 pointer d-flex align-items-center" :class="{'tab-active': ($route.path.startsWith('/product/details'))}">
                            <div class="right-line" v-if="($route.path.startsWith('/product/details'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                            </div>
                            <span class="fs-14 fw-500" :class="{'defaultDarkBlue':($route.path.startsWith('/product/details'))}">About</span>
                            <i class="icon-chevron-right arrow pr-1"  style="font-size: 26px;" :class="{'defaultDarkBlue':($route.path.startsWith('/product/details'))}"></i>
                        </div>
                        <div class="dropdown-content" style="border: 1px solid #303031 !important;background-color: #ffff !important;border-radius: 4px !important;">
                            <a class="user-tab pt-3 pb-2 pointer d-flex align-items-center" v-if="!expand">
                                <span class="fs-14 fw-400 pl-4 sub-menu-main" style="color: #303031 !important;">{{"-"}}</span>
                            </a>
                        </div>
                    </div>
                    <div class="dropdown mt-2" v-if="expand && ($route.path.startsWith('/product/details'))">
                        <div class="user-tab py-3 pointer d-flex align-items-center" :class="{'tab-active': ($route.path.startsWith('/product/details'))}">
                            <div class="right-line" v-if="($route.path.startsWith('/product/details'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                            </div>
                            <span class="fs-14 pl-10 fw-500" :class="{'defaultDarkBlue':($route.path.startsWith('/product/details'))}">About</span>
                            <i class="icon-chevron-right arrow pl-30"  style="font-size: 26px;" :class="{'defaultDarkBlue':($route.path.startsWith('/product/details'))}"></i>
                        </div>
                        <div class="dropdown-content" style="margin-left: 262px !important;border: 1px solid #303031 !important;background-color: #ffff !important;border-radius: 4px !important;">
                            <a class="user-tab pt-3 pb-2 pointer d-flex align-items-center" v-if="expand">
                                <span class="fs-14 fw-400 pl-2 sub-menu-main" style="color: #303031 !important;">{{products_data.about || "-"}}</span>
                            </a>
                        </div>
                    </div>
                    <div class="dropdown mt-2" v-if="!expand">
                        <div class="user-tab py-3 pl-6 pointer d-flex align-items-center" :class="{'tab-active': ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders')) || ($route.path == '/' || $route.path.startsWith('/userprofile')) && expand}">
                            <div class="right-line" v-if="($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                            </div>
                            <i class="crmnew-user iconsize-16" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}" style="font-size: 20px !important; color: #afafaf;width: 33px;height: 20px;"></i>
                        </div>
                        <div class="dropdown-content">
                            <a class="user-tab pt-3 pb-2 pointer d-flex align-items-center" v-if="!expand">
                                <span class="fs-14 fw-500 pl-6 sub-menu-main" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}">Admin Profile</span>
                            </a>
                            <div style="border: 1px solid #afafaf"></div>
                            <a class="user-tab pt-4 pointer d-flex align-items-center" @click="gotoPage('basicinfo')" v-if="!expand">
                                <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i>
                                <span class="fs-14 fw-normal pl-5 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo'))}">Basic Information</span>
                            </a>
                            <a class="user-tab pt-3 pointer d-flex align-items-center" @click="gotoPage('communication')" v-if="!expand">
                                <i class="crmnew-phone iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i>
                                <span class="fs-14 fw-normal pl-5 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication'))}">Communication</span>
                            </a>
                            <a class="user-tab pt-3 pointer d-flex align-items-center" @click="gotoPage('address')" v-if="!expand">
                                <i class="crmnew-marker iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/AddressView') || $route.path.startsWith('/address'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i>
                                <span  class="fs-14 fw-normal pl-5 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/AddressView') || $route.path.startsWith('/address'))}">Address</span>
                            </a>
                            <a class="user-tab pt-3 pointer d-flex align-items-center" @click="gotoPage('preferences')" v-if="!expand">
                                <i class="crmnew-preference iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i>
                                <span class="fs-14 fw-normal pl-5 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences'))}">Preferences</span>
                            </a>
                            <a class="user-tab pt-3 pointer d-flex align-items-center" @click="gotoPage('health')" v-if="!expand">
                                <span class="ml-4">
                                    <img v-if="($route.path.startsWith('/HealthView') || $route.path.startsWith('/health'))" height="15px important" width="15px !important" src="/static/images/health-icon-new-blue.svg" />
                                    <img v-else height="15px !important" width="15px !important" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/health-icon.svg" >
                                </span>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/HealthView') || $route.path.startsWith('/health'))}">Health</span>
                            </a>
                            <a class="user-tab pt-3 pb-2 pointer d-flex align-items-center" @click="gotoPage('reminders')" v-if="!expand">
                                <span class="ml-4">
                                    <img v-if="($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))" height="14px important" width="14px !important" src="/static/images/reminders-icon-new-blue.svg" />
                                    <img v-else height="14px !important" width="14px !important" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/reminders-icon.svg" >
                                </span>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}">Reminders</span>
                            </a>
                        </div>
                    </div>
                    <div class="mt-3" v-if="!expand">
                        <div class="user-tab py-3 pl-7 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/organization') || $route.path.startsWith('/product/details') || $route.path.startsWith('/consultants') || $route.path.startsWith('/employee') || $route.path.startsWith('/linkemp') || $route.path.startsWith('/company') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}" @click="gotoPage('company')">
                            <div class="right-line" v-if="($route.path.startsWith('/organization') || $route.path.startsWith('/company/listview') || $route.path.startsWith('/linkemp'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                            </div>
                            <span>
                                <img  v-if="($route.path.startsWith('/organization') || $route.path.startsWith('/product/details') || $route.path.startsWith('/linkemp') || $route.path.startsWith('/consultants') || $route.path.startsWith('/employee') || $route.path.startsWith('/company') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/company-blue.png" alt="company" />
                        <!-- <div class="user-tab py-3 pl-7 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/organization') || $route.path.startsWith('/company') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp'))}" @click="gotoPage('company')">
                            <div class="right-line" v-if="($route.path.startsWith('/organization') || $route.path.startsWith('/company/listview') || $route.path.startsWith('/linkemp'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                            </div>
                            <span>
                                <img  v-if="($route.path.startsWith('/organization') || $route.path.startsWith('/company') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp'))" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/company-blue.png" alt="company" /> -->
                                <img v-else height="20px" src="/static/images/company-gray.svg" alt="Company" />
                            </span>
                        </div>
                    </div>
                    
                    <div class="dropdowns mt-1"  v-if="!expand && show_company_menu && ($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))">
                        <div class="user-tab py-2 pl-3 ml-2 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}" style="height: 40px !important;">
                            <div class="right-line" v-if="($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                    <!-- <div class="dropdowns mt-1"  v-if="!expand && show_company_menu && ($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants'))">
                        <div class="user-tab py-2 pl-3 ml-2 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants'))}">
                            <div class="right-line" v-if="($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;"> -->
                            </div>
                            <span class="text-truncate" v-loading="ajax_call_in_progress" :title="comp_data.company_name" style="font-size: 12px;font-weight: 500;">{{comp_data.company_name}}</span>
                        </div>
                        <!-- style="height: 240px;overflow: auto;" -->
                        <div class="dropdown-contents dropdown-scroll">
                            <a class="user-tabs pt-4 pointer d-flex align-items-center pl-5" @click="gotoPage('info')" v-if="!expand">
                                <img v-if="$route.path.startsWith('/company/view/')" width="18px" height="15px" src="/static/images/Blue-Info.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Info.svg"/>
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/company/view/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/company/view/'))}">Basic Information</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('employee')" v-if="!expand">
                                <!-- <i class="crmnew-business iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/employee/listemployee/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="$route.path.startsWith('/employee')" width="18px" height="15px" src="/static/images/Blue-Employee.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Employee.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/employee'))}">Employees</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('businessunit')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/businessunit/listviews/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/businessunit/listview/'))" width="18px" height="15px" src="/static/images/Blue-Business.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Business.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/businessunit'))}">Business Unit</span>
                                <!-- <img v-if="($route.path.startsWith('/businessunit'))" width="18px" height="15px" src="/static/images/Blue-Business.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Business.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/businessunit'))}">Business Unit</span> -->
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('department')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/departmentunit/listview/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/departmentunit'))" width="18px" height="15px" src="/static/images/Blue-department.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Department.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/departmentunit'))}">Departments</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('comp_products')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/product/view/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/product/view/') || $route.path.startsWith('/product/details'))" width="18px" height="15px" src="/static/images/Blue-Product.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Product.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/product/view/') || $route.path.startsWith('/product/details'))}">Products</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('bank_details')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/company/bank_info/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/company/bank_info/'))" width="18px" height="15px" src="/static/images/Blue-Bank.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Bank.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/company/bank_info/'))}">Bank Details</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('outlet_details')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/company/outlet_info/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/company/outlet/'))" width="18px" height="15px" src="/static/images/Blue-Outlet.svg"/>
                                <!-- <img v-if="($route.path.startsWith('/company/outlet/'))" width="18px" height="15px" src="/static/images/Blue-Outlet.svg"/> -->
                                <img v-else width="18px" height="15px" src="/static/images/Outlet.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/company/outlet/'))}">Outlets</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('consultant')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants/listview/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/consultants'))" width="18px" height="15px" src="/static/images/Blue-Consultant.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Consultant.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants'))}">Consultants</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('investors')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants/listview/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/investors'))" width="18px" height="17px" src="/static/images/blue-Investors.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/black-investors.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/investors'))}">Investors</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('bankers')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants/listview/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/bankers'))" width="18px" height="17px" src="/static/images/blue-banker.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/banker.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/bankers'))}">Bankers</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('suppliers')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants/listview/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/supplier'))" width="18px" height="17px" src="/static/images/blue-suppl.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/supplier.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/supplier'))}">Suppliers</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('board')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants/listview/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/board'))" width="18px" height="17px" src="/static/images/boardmeeting-blue.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/boardmeeting-black.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/board'))}">Board Members</span>
                            </a>
                            <a class="user-tabs pt-2 pointer d-flex align-items-center pl-5" @click="gotoPage('shareholder')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants/listview/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="($route.path.startsWith('/shareholder'))" width="18px" height="17px" src="/static/images/shareholder-blue.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/shareholder-black.svg"/>
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/shareholder'))}">Shareholders</span>
                            </a>
                            <a class="user-tabs pt-2 pb-2 pointer d-flex align-items-center pl-5" @click="gotoPage('company_setting')" v-if="!expand">
                                <!-- <i class="crmnew-user iconsize-16 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/company/settings/'))}" style="font-size: 16px !important; color: #afafaf;width: 20px;height: 20px;"></i> -->
                                <img v-if="$route.path.startsWith('/company/settings/')" width="18px" height="15px" src="/static/images/Settings-Company-Blue.svg" alt="settings" />
                                <img v-else width="18px" height="15px" src="/static/images/Settings-Company-Gray.svg" alt="settings" />
                                <span class="fs-14 fw-normal pl-2 sub-menu-tabs" :class="{'defaultDarkBlue':($route.path.startsWith('/company/settings/'))}">Company Settings</span>
                            </a>
                            
                        </div>
                    </div>
                    <div style="overflow: auto;">
                    <ul class="settings-left-sec-list mt-3" v-if="expand">
                        <li v-if="expand">
                            <div class="user-tab py-2 pl-5 pointer d-flex align-items-center" :class="{'tab-active':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}">
                                <div v-if="($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                                </div>
                                <i v-if="expand" class="crmnew-user iconsize-16" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}" style="font-size: 20px !important; color: #afafaf;width: 30px;height: 20px;"></i>
                                <span v-if="expand" class="fs-14 fw-500" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}">Admin Profile</span>
                                <i v-if="sub_menu" @click="subMenu()" class="icon-chevron-up pl-16 arrow"  style="font-size: 32px;" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}"></i>
                                <i v-else class="icon-chevron-down pl-16 arrow" @click="subMenu()" :class="{'defaultDarkBlue':($route.path == '/' || $route.path.startsWith('/userprofile')) || ($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo')) || ($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication')) || ($route.path.startsWith('/AddressView') || $route.path.startsWith('/address')) || ($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences')) || ($route.path.startsWith('/HealthView') || $route.path.startsWith('/health')) || ($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}" style="font-size: 32px;"></i>
                            </div>
                        </li>
                        <div v-if="expand">
                        <li class="admin-profile-submenu pt-1" v-if="sub_menu">
                            <div class="user-tab py-1 pointer d-flex align-items-center" @click="gotoPage('basicinfo')">
                                <i class="crmnew-user iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/PersonalInfoView') || $route.path.startsWith('/basicinfo'))}">Basic Information</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-1 pointer d-flex align-items-center" @click="gotoPage('communication')">
                                <i class="crmnew-phone iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/CommunicationView') || $route.path.startsWith('/communication'))}">Communication</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-1 pointer d-flex align-items-center" @click="gotoPage('address')">
                                <i class="crmnew-marker iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/AddressView') || $route.path.startsWith('/address'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span  class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/AddressView') || $route.path.startsWith('/address'))}">Address</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-1 pointer d-flex align-items-center" @click="gotoPage('preferences')">
                                <i class="crmnew-preference iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i>
                                <span class="fs-14 fw-normal pl-1 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/PreferenceView') || $route.path.startsWith('/preferences'))}">Preferences</span>
                            </div>
                        </li>
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-1 pointer d-flex align-items-center" @click="gotoPage('health')">
                                <span v-if="expand">
                                    <img v-if="($route.path.startsWith('/HealthView') || $route.path.startsWith('/health'))" height="15px important" width="15px !important" src="/static/images/health-icon-new-blue.svg" />
                                    <img v-else height="15px !important" width="15px !important" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/health-icon.svg" ></span>
                                <!-- <img v-if="expand" src="/static/images/health-icon-default-blue.svg"/> -->
                                <!-- <i class="crmnew-user iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/HealthView') || $route.path.startsWith('/health'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i> -->
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/HealthView') || $route.path.startsWith('/health'))}">Health</span>
                            </div>
                        </li> 
                        <li class="admin-profile-submenu" v-if="sub_menu">
                            <div class="user-tab py-1 pointer d-flex align-items-center" @click="gotoPage('reminders')">
                                <span v-if="expand">
                                    <img v-if="($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))" height="14px important" width="14px !important" src="/static/images/reminders-icon-new-blue.svg" />
                                    <img v-else height="14px !important" width="14px !important" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/reminders-icon.svg" ></span>
                                <!-- <i class="crmnew-user iconsize-16 pt-1" :class="{'defaultDarkBlue':($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}" style="font-size: 16px !important; color: #afafaf;width: 25px;height: 25px;"></i> -->
                                <span class="fs-14 fw-normal pl-4 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/RemindersView') || $route.path.startsWith('/reminders'))}">Reminders</span>
                            </div>
                        </li>
                        <div v-if="sub_menu" style="border: solid 0.5px #d9d8d8" class="ml-2 mr-2"></div></div>
                    </ul>
                    <!-- <div class="user-tab py-3 pointer pl-4 d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company') || $route.path.startsWith('/organization' ) || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants')) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/linkemp')}" @click="gotoPage('company')">
                        <div v-if="($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' )  || $route.path.startsWith('/linkemp'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                        </div>
                        <div class="text-center" style="width: 28px;">
                            <el-tooltip v-if="!expand" class="item ml-2" effect="dark" content="Manage" placement="right">
                                <img v-if="($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants')  || $route.path.startsWith('/linkemp'))" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/company-blue.png" alt="company" />
                                <img v-else height="20px" src="/static/images/company-gray.svg" alt="Company" />
                            </el-tooltip>
                            <span v-if="expand">
                                <img v-if="($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants')  || $route.path.startsWith('/linkemp'))" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/company-blue.png" alt="company" />
                                <img v-else height="20px" src="/static/images/company-gray.svg" alt="Company" />
                            </span>
                        </div>
                        <span title="Manage Organization" v-if="expand && loggedInUser.user_has == 'organization'" class="fs-14 fw-500 pl-1" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants')  || $route.path.startsWith('/linkemp'))}">
                            Manage Organization
                        </span>
                        <span  title="Manage Company" v-else-if="expand && (loggedInUser.user_has == 'standalone' || loggedInUser.user_has == 'company')" class="fs-14 fw-600 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants'))}">
                            Manage Company
                        </span>
                        <span title="Manage Companies" v-else-if="expand && loggedInUser.user_has == 'companies'" class="fs-14 fw-500 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization') || $route.path.startsWith('/employee' ) || $route.path.startsWith('/businessunit' ) || $route.path.startsWith('/departmentunit' ) || $route.path.startsWith('/consultants'))}">
                            Manage Companies
                        </span>
                        <span title="Create Company" v-else-if="expand" class="fs-14 fw-600 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization'))}">
                            Create Company
                        </span>
                    </div> -->
                    <ul class="settings-left-sec-list mt-3" v-if="expand">
                        <li v-if="expand">
                            <div class="user-tab py-3 pl-5 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}" @click="gotoPage('company')">
                                <div v-if="($route.path.startsWith('/organization') || $route.path.startsWith('/company/listview') || $route.path.startsWith('/linkemp'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                                </div>
                                <span v-if="expand" class="pl-1">
                                    <img v-if="($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/company-blue.png" alt="company" />
                                    <img v-else height="20px" src="/static/images/company-gray.svg" alt="Company" />
                                </span>
                                <span title="Manage Organization" v-if="expand && loggedInUser.user_has == 'organization'" class="fs-14 fw-500 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/organization') || $route.path.startsWith('/product/details') || $route.path.startsWith('/company/view/') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/linkemp') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}">
                                    Manage Organization
                                </span>
                                <span  title="Manage Company" v-else-if="expand && (loggedInUser.user_has == 'standalone' || loggedInUser.user_has == 'company')" class="fs-14 fw-600 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/organization') || $route.path.startsWith('/company/view/') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/linkemp') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}">
                                    Manage Company
                                </span>
                                <span title="Manage Companies" v-else-if="expand && loggedInUser.user_has == 'companies'" class="fs-14 fw-500 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/organization') || $route.path.startsWith('/company/view/') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/linkemp') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}">
                            <!-- <div class="user-tab py-3 pl-5 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/organization') || $route.path.startsWith('/company') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp'))}" @click="gotoPage('company')">
                                <div v-if="($route.path.startsWith('/organization') || $route.path.startsWith('/company/listview') || $route.path.startsWith('/linkemp'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                                </div>
                                <span v-if="expand" class="pl-1">
                                    <img v-if="($route.path.startsWith('/organization') || $route.path.startsWith('/company') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp'))" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/company-blue.png" alt="company" />
                                    <img v-else height="20px" src="/static/images/company-gray.svg" alt="Company" />
                                </span>
                                <span title="Manage Organization" v-if="expand && loggedInUser.user_has == 'organization'" class="fs-14 fw-500 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/organization') || $route.path.startsWith('/company') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp'))}">
                                    Manage Organization
                                </span>
                                <span  title="Manage Company" v-else-if="expand && (loggedInUser.user_has == 'standalone' || loggedInUser.user_has == 'company')" class="fs-14 fw-600 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/organization') || $route.path.startsWith('/company') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp'))}">
                                    Manage Company
                                </span>
                                <span title="Manage Companies" v-else-if="expand && loggedInUser.user_has == 'companies'" class="fs-14 fw-500 pl-4" :class="{'defaultDarkBlue':($route.path.startsWith('/organization') || $route.path.startsWith('/company') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants') || $route.path.startsWith('/linkemp'))}"> -->
                                    Manage Companies
                                </span>
                                <span title="Create Company" v-else-if="expand" class="fs-14 fw-600 pl-3" :class="{'defaultDarkBlue':($route.path.startsWith('/company') || $route.path.startsWith('/organization'))}">
                                    Create Company
                                </span>
                            </div>
                        </li>
                    </ul>
                    <ul class="settings-left-sec-list mt-1" v-if="expand && show_company_menu && ($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))">
                        <li v-if="expand">
                            <div class="user-tab py-1 pl-3 ml-10 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}">
                                <div v-if="($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/employee') || $route.path.startsWith('/product/details') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/consultants') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                                </div>
                                <span style="font-size: 12px;font-weight: 500;width: 82%" :title="comp_data.company_name" v-loading="ajax_call_in_progress">{{comp_data.company_name}}</span>
                                <span>
                                    <i v-if="company_menu" @click="companyMenu()" class="icon-chevron-up arrow"  style="font-size: 32px;" :class="{'defaultDarkBlue':($route.path.startsWith('/company/view/') || $route.path.startsWith('/product/details') || $route.path.startsWith('/employee/listemployee/') || $route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/departmentunit/listview/') || $route.path.startsWith('/product/view/') || $route.path.startsWith('/company/bank_info/') || $route.path.startsWith('/company/outlet/') || $route.path.startsWith('/company/settings/')  || $route.path.startsWith('/consultants/listview/') || $route.path.startsWith('/investors') || $route.path.startsWith('/bankers') || $route.path.startsWith('/suppliers') || $route.path.startsWith('/board') || $route.path.startsWith('/shareholder'))}"></i>
                    <!-- <ul class="settings-left-sec-list mt-1" v-if="expand && show_company_menu && ($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants'))">
                        <li v-if="expand">
                            <div class="user-tab py-1 pl-3 ml-10 pointer d-flex align-items-center" :class="{'tab-active':($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants'))}">
                                <div v-if="($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants'))" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                                </div>
                                <span style="font-size: 12px;font-weight: 500;width: 82%" v-loading="ajax_call_in_progress">{{comp_data.company_name}}</span>
                                <span>
                                    <i v-if="company_menu" @click="companyMenu()" class="icon-chevron-up arrow"  style="font-size: 32px;" :class="{'defaultDarkBlue':($route.path.startsWith('/company/view/') || $route.path.startsWith('/employee') || $route.path.startsWith('/businessunit') || $route.path.startsWith('/departmentunit') || $route.path.startsWith('/product/view') || $route.path.startsWith('/company/bank_info') || $route.path.startsWith('/company/outlet') || $route.path.startsWith('/company/settings')  || $route.path.startsWith('/consultants'))}"></i> -->
                                    <i v-else class="icon-chevron-down arrow" @click="companyMenu()" style="font-size: 32px;"></i>
                                </span>
                            </div>
                        </li>
                        <div v-if="expand">
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('info')">
                                    <img v-if="$route.path.startsWith('/company/view/')" width="18px" height="15px" src="/static/images/Blue-Info.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Info.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/view/'))}">Basic Information</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('employee')">
                                    <img v-if="$route.path.startsWith('/employee')" width="18px" height="15px" src="/static/images/Blue-Employee.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Employee.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/employee'))}">Employees</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('businessunit')">
                                    <img v-if="($route.path.startsWith('/businessunit/listview/'))" width="18px" height="15px" src="/static/images/Blue-Business.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Business.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/businessunit/listview/') || $route.path.startsWith('/businessunit'))}">Business Unit</span>
                                    <!-- <img v-if="($route.path.startsWith('/businessunit'))" width="18px" height="15px" src="/static/images/Blue-Business.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Business.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/businessunit'))}">Business Unit</span> -->
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('department')">
                                    <img v-if="($route.path.startsWith('/departmentunit'))" width="18px" height="15px" src="/static/images/Blue-department.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Department.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/departmentunit'))}">Departments</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('comp_products')">
                                    <img v-if="($route.path.startsWith('/product/view/') || $route.path.startsWith('/product/details'))" width="18px" height="15px" src="/static/images/Blue-Product.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Product.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/product/view/') || $route.path.startsWith('/product/details'))}">Products</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('bank_details')">
                                    <img v-if="($route.path.startsWith('/company/bank_info/'))" width="18px" height="15px" src="/static/images/Blue-Bank.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Bank.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/bank_info/'))}">Bank Details</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('outlet_details')">
                                    <img v-if="($route.path.startsWith('/company/outlet/'))" width="18px" height="15px" src="/static/images/Blue-Outlet.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Outlet.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/outlet/'))}">Outlets</span>
                                    <!-- <img v-if="($route.path.startsWith('/company/outlet'))" width="18px" height="15px" src="/static/images/Blue-Outlet.svg"/>
                                    <img v-else width="18px" height="15px" src="/static/images/Outlet.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/outlet'))}">Outlet</span> -->
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('consultant')">
                                    <img v-if="($route.path.startsWith('/consultants'))" width="18px" height="15px" src="/static/images/Blue-Consultant.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/Consultant.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/consultants'))}">Consultants</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('investors')">
                                    <img v-if="($route.path.startsWith('/investors'))" width="18px" height="17px" src="/static/images/blue-Investors.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/black-investors.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/investors'))}">Investors</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('bankers')">
                                    <img v-if="($route.path.startsWith('/bankers'))" width="18px" height="17px" src="/static/images/blue-banker.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/banker.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/bankers'))}">Bankers</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('suppliers')">
                                    <img v-if="($route.path.startsWith('/suppliers'))" width="18px" height="17px" src="/static/images/blue-suppl.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/supplier.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/suppliers'))}">Suppliers</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('board')">
                                    <img v-if="($route.path.startsWith('/board'))" width="18px" height="17px" src="/static/images/boardmeeting-blue.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/boardmeeting-black.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/board'))}">Board Members</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('shareholder')">
                                    <img v-if="($route.path.startsWith('/shareholder'))" width="18px" height="17px" src="/static/images/shareholder-blue.svg"/>
                                <img v-else width="18px" height="15px" src="/static/images/shareholder-black.svg"/>
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/shareholder'))}">Shareholders</span>
                                </div>
                            </li>
                            <li class="admin-profile-submenu" v-if="company_menu">
                                <div class="user-tabs py-1 pl-6 pointer d-flex align-items-center" @click="gotoPage('company_setting')">
                                    <img v-if="$route.path.startsWith('/company/settings/')" width="18px" height="15px" src="/static/images/Settings-Company-Blue.svg" alt="settings" />
                                    <img v-else width="18px" height="15px" src="/static/images/Settings-Company-Gray.svg" alt="settings" />
                                    <span class="fs-14 fw-normal pl-2 profile-submenu" :class="{'defaultDarkBlue':($route.path.startsWith('/company/settings/'))}">Company Settings</span>
                                </div>
                            </li>
                        </div>
                    </ul>
                    <div v-if="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'Admin' || loggedInUser.user_role == 'employee'" title="Product Access" class="user-tab py-3 mt-2 mb-2 pl-4 pointer d-flex align-items-center" :class="{'tab-active':$route.path == '/product/view'}" @click="gotoPage('products')">
                        <div v-if="$route.path == '/product/view'" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                        </div>
                        <el-tooltip v-if="!expand" class="item ml-2" effect="dark" content="Product Access" placement="right">
                            <img v-if="$route.path == '/product/view'" width="28px" height="20px" src="/static/images/products-setting-blue.svg" alt="product_access" />
                            <img v-else width="28px" height="20px" src="/static/images/products-setting.svg" alt="product_access" />
                        </el-tooltip>
                        <span v-if="expand" class="pl-1">
                            <img v-if="$route.path == '/product/view'" width="28px" height="20px" src="/static/images/products-setting-blue.svg" alt="product_access" />
                            <img v-else width="28px" height="20px" src="/static/images/products-setting.svg" alt="product_access" />
                        </span>
                        <span v-if="expand" class="fs-14 fw-500 pl-2" :class="{'defaultDarkBlue':$route.path == '/product/view'}">Product Access</span>
                    </div>
                    <div class="user-tab py-3 mt-2 mb-2 pl-4 pointer d-flex align-items-center" :class="{'tab-active':$route.path.startsWith('/settings')}" @click="gotoPage('settings')" title="Settings">
                        <div v-if="$route.path.startsWith('/settings')" style="width: 4.5px;height: 100%;background-color: #00448b; position: absolute; right: 0;">
                        </div>
                        <el-tooltip v-if="!expand" class="item ml-2" effect="dark" content="Settings" placement="right">
                            <img v-if="$route.path.startsWith('/settings')" width="20px" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" alt="settings" />
                            <img v-else width="20px" height="20px" src="/static/images/settings-gray.svg" alt="settings" />
                        </el-tooltip>
                        <span v-if="expand" class="pl-1">
                            <img v-if="$route.path.startsWith('/settings')" width="20px" height="20px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" alt="settings" />
                            <img v-else width="20px" height="20px" src="/static/images/settings-gray.svg" alt="settings" />
                        </span>
                        <span v-if="expand" class="fs-14 fw-500 pl-2" :class="{'defaultDarkBlue':$route.path.startsWith('/settings')}">Settings</span>
                    </div>
                </div>
                </div>
            </nav>
        </div>
        <main class="w-100 main-menu" :class="{'expand-menu':expand}">
            <div class="w-100 h-100">
                <router-view/>
            </div>
        </main>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
	import {
		EventBus
	} from "./eventBus/nav-bar-event.js";
    import axios from 'axios';
    import { SweetModal } from 'sweet-modal-vue';
    import profile from './mixins/profile.js';
    import companies from './mixins/companies.js';
	export default {
		data() {
			return {
                products_data:{},
                warning_msg: "",
                success_msg: "",
                expandMenu: false,
				expand: false,
                sub_menu:false,
                open_sub_menu:true,
                open_company_sub:true,
                open_company_menu:true,
                company_menu:false,
                user_company:[],
                comp_data:{},
                company_name:"",
                main_company:false,
                ajax_call_in_progress: false,
                sub_menu_organization: false,
			};
		},
		mixins: [profile, companies],
		components: {
            SweetModal
		},
		methods: {
            async deleteProfile(){
                try {
                    let param = {};
                        param.profile_pic = "";
                    let response = await this.updatePersonalInfo(param, this.loggedInUser.user_id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message;
                        EventBus.$emit('personal_info_data_update')
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                        }, 3000);
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
                // this.profile_attachment = {}
            },
            // async getProductsList(comp_id) {
            //     try {
            //         let params = {
            //             company_id: comp_id,
            //             search_key: '',
            //             skip: 0,
            //             limit: 10
            //         };
            //         let response = await this.getAllProducts(params);
            //         if(response.status_id == 1) {
            //             let prod_data = response;
            //             this.products_data = prod_data.subscribed_products.filter(word => word.id === this.$route.params.id);
            //             this.$forceUpdate()
            //         }
            //     }
            //     catch(err) {
            //     }
            // },
            async updateInfo(type, url) {
                try {
                    let params = {
                    };
                    if (type == 'cover_pic') {
                        params.cover_image = url;
                        let response = await this.editCompany(params, this.$route.params.id);
                        if(response.status_id == 1) {
                            this.comp_data.cover_image = url;
                        }
                    } else if (type == 'profile_pic') {
                        params.logo = url;
                        let response = await this.editCompany(params, this.$route.params.id);
                        if(response.status_id == 1) {
                            this.comp_data.logo = url;
                        }
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            
            async getCompData() {
                if(this.selected_company_id == 'company id not present' || this.selected_company_id == null || this.selected_company_id == undefined || this.selected_company_id == '') {

                } else {
                    this.ajax_call_in_progress = true;
                    try {
                        let response = await this.getCompanyById(this.selected_company_id);
                        if (response.status_id == 1) {
                            let res_data = response.response
                            this.comp_data.company_name = res_data.name
                            this.$forceUpdate()
                        }
                        this.ajax_call_in_progress = false;
                    }
                    catch(err) {
                        // console.log(err)
                    }
                }
            },
            onUserProfile(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "profile_pic");
                }
            },
            getResumableURLprofile(file, attachment, type) {
                let data = {
                    file: attachment.name,
                };
                data = new FormData();
                data.append("file", file);
                this.$http
                    .post("https://cloudapis.digicollect.com/external/upload_file", data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            this.updatePersonalData(response.data.url, type);
                        }
                    })
                    .catch(error => {
                        // this.error = "Error Uploading"
                    });
            },
            async updatePersonalData(data, type) {
                try {
                    let param = {};
                    if(type == 'profile_pic') {
                        param.profile_pic = data;
                    } else if(type == 'cover_pic') {
                        param.cover_pic = data;
                    }
                    let response = await this.updatePersonalInfo(param, this.loggedInUser.user_id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message;
                        EventBus.$emit('personal_info_data_update')
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                        }, 3000);
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            },
            // async getOrganizationAndComp() {
			// 	/**@params = skip  @params = limit, @params = search_key*/
			// 	try {
			// 		let param = {
			// 			skip: this.skip,
			// 			limit: this.comp_limit,
			// 			search_key: this.search_key,
			// 		};
			// 		let response = await this.getHierarchyListView(param);
			// 		if(response.status_id == 1) {
			// 			this.comp_data = response.response;
			// 			this.comp_data.forEach((element, index) => {
			// 				element['org_active'] = false
			// 				element.company_list.forEach((element1, index1) => {
			// 					if(element1.selected) {
			// 						this.outer_el = index;
			// 						this.inner_el = index1;
			// 					}
			// 					return;
			// 				});
			// 				return;
			// 			});
			// 			var element = this.comp_data[this.outer_el];
			// 			var element1 = element.company_list[this.inner_el];
			// 			element.company_list.splice(this.inner_el, 1);
			// 			element.company_list.splice(0, 0, element1);
			// 			this.comp_data.splice(this.outer_el, 1);
			// 			this.comp_data.splice(0, 0, element);
			// 			this.user_company = response.user_company;
			// 			this.count1 = response.response.length;
			// 		}
			// 	}
			// 	catch(err) {
			// 	}
			// },
            subMenu(){
                if(this.open_sub_menu == false){
                    this.sub_menu = false;
                    this.open_sub_menu = true;
                }else{
                    this.sub_menu = true;
                    this.open_sub_menu = false;
                }
            },
            subMenuOrganization(){
                if(this.open_sub_menu == false){
                    this.sub_menu_organization = false;
                    this.open_sub_menu = true;
                }else{
                    this.sub_menu_organization = true;
                    this.open_sub_menu = false;
                }
            },
            companyMenu(){
                if(this.open_company_sub == false){
                    this.company_menu = false;
                    this.open_company_sub = true;
                }else{
                    this.company_menu = true;
                    this.open_company_sub = false;
                }
            },
            expandLeftBar() {
                this.expand = !this.expand;
            },
            expandLeftMenu(){
                this.expandMenu = !this.expandMenu;
                // EventBus.$emit('ExpandMenuBar', this.expandMenu);
            },
			gotoPage(type) {
                switch(type) {
                    case 'userprofile':
                        this.$router.push('/').catch((err)=>{
                        });
                        break;
                    case 'basicinfo':
                        this.$router.push('/PersonalInfoView/'+this.loggedInUser.user_id).catch((err)=>{
                        });
                        break;
                    case 'communication':
                        this.$router.push('/CommunicationView/'+this.loggedInUser.user_id).catch((err)=>{
                        });
                        break;
                    case 'address':
                        this.$router.push('/AddressView/'+this.loggedInUser.user_id).catch((err)=>{
                        });
                        break;
                    case 'preferences':
                        this.$router.push('/PreferenceView/'+this.loggedInUser.user_id).catch((err)=>{
                        });
                        break;
                    case 'health':
                        this.$router.push('/HealthView/'+this.loggedInUser.user_id).catch((err)=>{
                        });
                        break;
                    case 'reminders':
                        this.$router.push('/RemindersView/'+this.loggedInUser.user_id).catch((err)=>{
                        });
                        break;
                    case 'createCompany':
                        this.$router.push('/CreateOrgCompany/'+this.loggedInUser.user_id).catch((err)=>{
                        });
                        break;
                    case 'info':
                        this.$router.push('/company/view/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'company':
                        if(this.loggedInUser.has_organization_or_standalone) {
                            if(this.loggedInUser.user_has == 'organization') {
                                this.$router.push('/organization/listview').catch((err)=>{
                                });
                            } else if(this.loggedInUser.user_has == 'standalone') {
                                this.$router.push('/company/listview').catch((err)=>{
                                });
                            } else if(this.loggedInUser.user_has == 'companies') {
                                this.$router.push('/organization/listview').catch((err)=>{
                                });
                            } else if(this.loggedInUser.user_has == 'company') {
                                this.$router.push('/company/listview').catch((err)=>{
                                });
                            }
                        } else {
                            this.$router.push('/company/createcompanyororganization').catch((err)=>{
                            });
                        }
                        break;
                    case 'settings':
                        this.$router.push('/settings/general').catch((err)=>{
                        });
                        break;
                    case 'products' :
                        this.$router.push('/product/view').catch((err)=>{
                        });
                        break;
                    case 'comp_products' :
                        this.$router.push('/product/view/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'employee' :
                        this.$router.push('/employee/listemployee/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'businessunit' :
                        this.$router.push('/businessunit/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'department' :
                        this.$router.push('/departmentunit/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'bank_details' :
                        this.$router.push('/company/bank_info/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'outlet_details' :
                        this.$router.push('/company/outlet/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'company_setting' :
                        this.$router.push('/company/settings/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'consultant' :
                        this.$router.push('/consultants/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'investors' :
                        this.$router.push('/investors/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'bankers' :
                        this.$router.push('/bankers/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'suppliers' :
                        this.$router.push('/suppliers/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'board' :
                        this.$router.push('/board/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    case 'shareholder' :
                        this.$router.push('/shareholder/listview/cmpid/'+this.selected_company_id).catch((err)=>{
                        });
                        break;
                    default:
                        return;
                }
            }
		},
		mounted() {
            EventBus.$on('comp_api_call_for_menubar_new', () => {
                this.getCompData();
            })
            // this.getProductsList(this.$route.params.company_id);
            if(this.show_company_menu) {
                this.comp_data = {};
                this.getCompData();
            }
            if(this.$route.path == '/') {
                if (this.$route.params.id) {
                    // this.personalInfoData();
                } else {
                    this.$router.push("/PersonalInfoView/" + this.loggedInUser.id);
                }
            }
            setTimeout(() => {
                EventBus.$on('comp_api_call_for_menubar', ()=>{
                    // if(this.show_company_menu) {
                        // this.comp_data = {};
                        // this.getCompData();
                    // }
                })
            }, 1000);
		},
        created() {
            EventBus.$on("expandLeftMenu", this.expandLeftMenu);
        },
        destroyed() {
            EventBus.$off("expandLeftMenu", this.expandLeftMenu);
        },
        watch: {
            // "$route.path": function (path) {
            //     if(this.show_company_menu) {
            //         this.comp_data = {};
            //         this.getCompData();
            //     }
            // },
        },
		computed: {
			loggedInUser() {
				return this.$store.state.loggedInUser;
			},
            show_company_menu() {
                let comp_data_present = localStorage.getItem('comp_data_for_menubar') ? true : false;
                let comp_id_present = (this.$route.path.includes('cmpid') && this.$route.params.company_id) ? true : false
                if(comp_data_present || comp_id_present) {
                    // this.comp_data = {};
                    // this.getCompData();
                }
                    return comp_data_present || comp_id_present;
                },
            selected_company_id() {
                let comp_data_present = localStorage.getItem('comp_data_for_menubar') ? true : false;
                let comp_data = JSON.parse(localStorage.getItem('comp_data_for_menubar'));
                let comp_id_present = (this.$route.path.includes('cmpid') && this.$route.params.company_id) ? true : false
                if(comp_id_present) {
                    return this.$route.params.company_id;
                } else if(comp_data_present) {
                    return comp_data.company_id
                } else {
                    return 'company id not present';
                }
            }
			// userAccounts() {
			// 	return this.$store.state.userAccounts;
			// },
			// isBusinessAccount() {
			// 	return this.$store.state.isBusinessAccount;
			// },
			// account() {
			// 	return this.$store.state.account;
			// },
			// selectedCompany() {
			// 	return this.$store.state.selectedCompany;
			// }
		},
		
	};
</script>

<style>
    .delete-image {
        position: absolute;
        /* right: 0; */
        bottom: 4px;
        left: 0px;
    }
	.left-navbar {
        width: 80px !important;
        min-width: 80px !important;
        /* height: 100%; */
        /* min-height: 40em; */
        padding: 10px 0;
        border: solid 0.5px #000;
        background-color: #fff;
        position: fixed;
        z-index: 15 !important;
        left: 0;
        top: 68px;
        bottom: 68px;
        transition: .3s;
        /* overflow-y: auto;
        overflow-x: hidden; */
    }
    .left-navbar-open {
        width: 260px !important;
        box-shadow: 0 0 10px 0 #404040;
        /* margin-left: 250px; */
    }
    .user-tab span {
        color: #afafaf;
    }
    .user-tabs span {
        color: #4e4e4e;
    }
    .user-tab.tab-active {
        border-radius: 4px;
        background-color: #ecf1f9;
    }
    .user-tab.tab-active span {
        color: #00448b;
    }
    .user-tab {
        padding: 0 10px;
        margin: 0 auto;
        position: relative;
    }
    .left-navbar-open .user-tab {
        margin: 0;
        /* margin-right: auto; */
    }
    
    .open-full-menu-expand {
        transform: rotate(180deg);
    }
    .settings-left-sec-list {
        padding-top: 0px;
        padding-left: 0;
    }
    .settings-left-sec-list li {
        list-style: none;
        /* margin: 10px 0;
        padding: 8px 20px; */
        border-radius: 2.5px;
        letter-spacing: 0.43px;
        color: #3f3f3f;
    }
    .settings-left-sec-list li.activeOption {
        color: #00448b;
        background-color: #ecf1f9;
    }
    .settings-left-sec-list li.salesOption {
        color: #00448b;
        background-color: #fff;
    }
    .admin-profile-submenu{
        /* margin-top: -65px !important; */
        margin-left: 25px !important;
    }
    .dropbtn {
        /* color: white; */
        /* padding: 16px; */
        font-size: 16px;
        border: none;
    }
    .dropdowns {
        position: relative;
        display: inline-block;
    }

    .dropdown-contents {
        display: none;
        position: absolute;
        background-color: #ecf1f9;
        min-width: 170px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        margin-left:80px;
        margin-top: -35px;
        border-radius: 6px;
        height: 424px;
    }

    .dropdown-contents a {
        color: black;
        text-decoration: none;
        display: block;
        margin-left: -15px;
    }

    .dropdowns:hover .dropdown-contents {display: block;}
    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #ecf1f9;
        min-width: 170px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        margin-left:80px;
        margin-top: -35px;
        border-radius: 6px;
    }

    .dropdown-content a {
        color: black;
        text-decoration: none;
        display: block;
        margin-left: -15px;
    }
    .dropdown:hover .dropdown-content {display: block;}
    .right-line:hover{
        width: 4.5px !important;height: 100% !important;background-color: #00448b !important; position: absolute !important; right: 0 !important;
    }
    .sub-menu-main{
        color: #00448b !important;
    }
    .user-tab:hover span,
    .user-tab:hover i{
        color: #00448b !important;
    }
    .user-tabs:hover span,
    .user-tabs:hover i{
        color: #00448b !important;
    }
    /* .dropdown:hover {
        background-color: #ecf1f9;
    } */
    .hover{
        color: #00448b !important;
    }
    /* .iconsize-16:hover{
        color: #00448b !important;
    }
    .profile-submenu:hover{
        color: #00448b !important;
    } */
    /* .dropdown.dropbtn.user-tab:hover>i {
        color: #00448b !important;
    }
    .dropdown.dropbtn#user-tab:hover>a i{
        color: #00448b !important;
    } */
    @media only screen and (min-width: 769px){
        .left-navbars{
            display: none;
        }
        .open-full-menu {
            background-color: #00448b;
            border-radius: 50%;
            position: absolute;
            right: -16px;
            cursor: pointer;
            z-index: 2;
            top:14px;
        }
        .mobile-menu{
            display: none;
        }
        .main-menu{
            /* padding-left: 1.5rem !important; */
            padding-bottom: 100px;
        }
    }
    @media only screen and (max-width: 768px){
        .admin-profile-submenu{
            margin-top: -65px !important;
            margin-left: 15px !important;
        }
        .left-navbar{
            display: none;
        }
        .open-full-menu{
            background-color: #00448b;
            border-radius: 50%;
            position: absolute;
            left: 5px;
            cursor: pointer;
            z-index: 2;
            top:-20px;

        }
        .left-navbars {
            padding: 10px 0;
            position: fixed;
            z-index: 15 !important;
            transition: .3s;
            top: 40px;
            left: -15px;
            cursor: pointer;
        }
        .mobile-menu{
            width: 230px !important;
            min-width:230px !important;
            padding: 10px 0;
            border: solid 0.5px #005cab;
            background-color: #fff;
            position: fixed;
            z-index: 15 !important;
            left: 0;
            top: 67px !important;
            bottom: 68px;
            transition: .3s;
            top: 10px;
            left: -15px;
            cursor: pointer;
        }
        .expand-menu{
            display: none;
        }
        .main-menu{
            padding-left: 0.7rem !important;
            padding-bottom: 25px;
        }
    }
    .main-menu{
        padding-left: 8.7rem;
    }
    .expand-menu{
        padding-left: 27rem !important;
    }
    .profilePicEdit-parent {
        position: absolute;
        right: 0;
        bottom: 5px;
    }
    .user-profile-popover{
        background: #ecf1f9 !important;
    }
    .margin-top{
        margin-top: 30px;
    }
    ul {
        margin-top: 0;
        margin-bottom: 0px !important;
    }
    @media only screen and (max-width: 1300px){
        .dropdown-scroll{
            max-height: 250px;
            overflow: auto;
        }
    }
    .dropdown-scroll::-webkit-scrollbar {
        width: 2px !important;
        height: 1px !important;
    }
    .dropdown-scroll::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
        background-color: #00448b !important;
    }
</style>